import { Component, Input, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { BaseService } from '../../Services/base.service';
import { ServiceLocator } from '../../Services/locator.service';
import { BizSelectComponent } from '../biz-select/biz-select.component';

@Component({
    selector: 'employee-info',
    templateUrl: 'employee-info.component.html',
})
export class EmployeeInfoComponent implements OnInit {

    @Input() employeeID: number = 0;

        empForm = new FormGroup({
        employeeID: new FormControl(),
        fullName: new FormControl(),
        oldCardNo: new FormControl(),
        department: new FormControl(),
        designation: new FormControl(),
        jdate: new FormControl(),
        cdate: new FormControl(),
        group: new FormControl(),
        type: new FormControl(),
        category: new FormControl(),
    });
    employeeParams: any[] = [];
    employeeData: any[] = [];

    @ViewChild('empSelect') empSelect: BizSelectComponent;

    private baseService: any;
    data: any;

    constructor() {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    public async ClearForm() {
        Object.keys(this.empForm.controls).map(el => {
            let ctr = this.empForm.get(el);
            if (ctr) {
                this.empForm.controls[el].setValue(undefined);
                ctr.markAsUntouched({ onlySelf: true });
                ctr.markAsPristine({ onlySelf: true });
            }
        })
        this.empSelect.data = [];
    }

    async getEmployee(id) {
        if (id > 0) {
            let val = "tType=" + 0 + "&flocation=" + localStorage.getItem('LocationID') + '&UserID=' + localStorage.getItem('UserID') + '&filed=employeeID' + '&value=' + id;

            await this.baseService.get('Employee', 'getEmployee', val, 'apiURL').then(async (i: any) => {

                if (i) {
                      i.map(o => {
                            if (o.jdate) {
                                o.jdate = moment(o.jdate).format('DD-MMM-yyyy')
                            }
                            if (o.cdate) {
                                o.cdate = moment(o.cdate).format('DD-MMM-yyyy')
                            }
                            return o;
                        })

                    this.empSelect.data = i
                }
            })
        }
    }

    async displayInfo(id, e) {

        if (id > 0 && this.empSelect && this.empSelect.data && this.empSelect.data.length > 0) {

            let obj = this.empSelect.data.filter(o => o.employeeID === Number(id))[0];

            if (obj) {

                this.empForm.controls['employeeID'].setValue(obj.employeeID)
                this.empForm.controls['fullName'].setValue(obj.fullName)
                this.empForm.controls['oldCardNo'].setValue(obj.oldCardNo)
                this.empForm.controls['department'].setValue(obj.department)
                this.empForm.controls['designation'].setValue(obj.designation)
                this.empForm.controls['cdate'].setValue(obj.cdate)
                this.empForm.controls['jdate'].setValue(obj.jdate)
                this.empForm.controls['type'].setValue(obj.type)
                this.empForm.controls['group'].setValue(obj.group)
                this.empForm.controls['category'].setValue(obj.category)
            }
        }
    }

    public async afterLoad() {

        if (this.employeeID > 0) {

            await this.getEmployee(this.employeeID)
            await this.displayInfo(this.employeeID, null)
        }
    }

    async ngOnInit() {
        this.employeeParams = [
            { name: 'tType', value: '0' },
            { name: 'flocation', value: localStorage.getItem('LocationID') },
            { name: 'USerID', value: localStorage.getItem('UserID') },
            { name: 'filed', value: 'fullName' },
            { name: 'value', value: 'value' }
        ]
        if (this.employeeID > 0) {
            await this.getEmployee(this.employeeID)
            await this.displayInfo(this.employeeID, null)
        }
    }
}
