import { Component, OnInit, AfterViewInit, Input, ViewChild, TemplateRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { Enum } from '../config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BizDatatableComponent } from '../biz-datatable/biz-datatable.component';
import { Broadcast } from '../../Services/broadcast.service';

@Component({
    selector: 'biz-lookup',
    templateUrl: 'lookup.component.html',
    styleUrls: ['lookup.component.scss']
})
export class LookupComponent implements OnInit, AfterViewInit {

    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() pagination: boolean = true;
    @Input() caption: string = "Lookup";
    itemOptionsOrders: any;
    itemOrder: any;
    // @Input() type: number = 1;

    @Input() public selectedRows: any[] = [];

    @ViewChild('lookupModal') lookupModal: TemplateRef<any>;
    @ViewChild('table') table: BizDatatableComponent;

    public _columns: any[] = [];
    public _type: number = 4;
    modalRef: any;
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 50
            };
            this._columns.splice(0, 0, selectCol);
        }
    }

    constructor(private modalService: BsModalService, private broadcast: Broadcast) {

    }

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    async ngOnInit() { }

    async ngAfterViewInit() { }

    async open() {

        if (this._columns.length > 0) {
            let arr: any[] = [];
            this._columns.map(i => {
                i.value = i.prop;
                i.label = i.name;

                if (i.prop !== 'selected')
                    arr.push(i)
                return i;
            });

            this.itemOrder = arr[0];
            this.itemOptionsOrders = [...arr];
        }

        if (this.rows.length > 0) {
            setTimeout(() => {
                this.caption = this.caption && this.caption !== "" ? this.caption : 'Lookup'
                this.modalRef = this.modalService.show(this.lookupModal, {
                    animated: true,
                    backdrop: 'static',
                    class: 'modal-lg',
                });

            }, 0);
        } else {

        }
    }

    @Input()
    public LookupId: String = "";

    Click(row) {
        
        let obj = {
            grid: this.table,
            row: row
        }
        
        if (obj) {
            
            if (this.modalRef)
                this.modalRef.hide()
            // this.broadcast.publish<LookupResult>("lookup", <LookupResult>{
            //     lookupId: this.LookupId,
            //     data: row,
            // });
            
            this.onClick.emit(obj);
        }
    }

    onSelection(selection) {

        if (selection) {

        }
    }

    save() {

        if (this.selectedRows) {

            this.modalRef.hide();
            return this.selectedRows;

        }
    }

    itemsPerPageChange(e) {

    }
    tempData: any[] = []
    searchKeyUp(obj) {

        const val = obj.event.target.value;

        if (val === "" && this.tempData.length > 0) {

            this.rows = [...this.tempData]
            this.tempData = [];
        }

        if (this.tempData.length === 0) this.tempData = this.rows;

        this.rows = this.rows.filter(i => i[obj.item.value].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== - 1);

    }


}

export interface LookupResult {
    lookupId: string;
    data: any;
}
