<!--component html goes here -->
<!--component html goes here -->

<ng-template #lookupModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{caption}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="lookupForm">
            <div class="row">
                <div class="col-lg-4">
                    <biz-dropdown caption="Store" [design]="2" [data]="store" formControlName="store"
                        (change)="FillAccHead(1,lookupForm.controls['store'].value)" [valueMember]="'id'"
                        [displayMember]="'name'">
                    </biz-dropdown>
                </div>
                <div class="col-lg-4">
                    <biz-dropdown caption="Accessory Head" [design]="2" [data]="head" formControlName="acchead"
                    (change)="changeHead(lookupForm.controls['acchead'].value)" [valueMember]="'id'" [displayMember]="'name'">
                    </biz-dropdown>
                </div>
            </div>
        </form>
        <app-list-page-header [isheading]="false" displayMode="list" [itemOptionsPerPage]="[2, 3, 4, 5, 10, 20]" [showDisplayMode]="false"
            [itemsPerPage]="15" [itemOptionsOrders]="itemOptionsOrders" [showBtnDropDown]="false" [isHeader]="false" [btnSearch]="false"
            (itemsPerPageChange)="itemsPerPageChange($event)" [itemOrder]="itemOrder" [showBtnAdd]="false" [serachAccessories]="true"
            [buttonState]="buttonState" [buttonDisabled]="buttonDisabled"
            (searchByButton)="searchByButtonAccessory($event)" [showItemsPerPage]="false" ></app-list-page-header>
        <div class="row">
            <div class="col-12 mb-4 data-table-rows actionGrid" style="margin-bottom: 15px;">
                <biz-datatable #table [type]="_type" [rows]="rows" [limit]="limit" [columns]="_columns"
                    [footerHeight]="50" (onClick)="Click($event)" [selected]="selectedRows" [selectionType]="'checkbox'"
                    (onSelection)='onSelection($event)'>
                </biz-datatable>
                <!-- <ngx-datatable *ngIf="type === 1" [columnMode]="columnMode" [rows]="rows" [limit]="limit" [headerHeight]="headerHeight"
                    [scrollbarH]="scrollbarH" [footerHeight]="100" #table [sorts]="[{ prop: 'title', dir: 'asc' }]">
                    <ngx-datatable-column name="Action" [flexGrow]="5" [width]="70" [cellClass]="'actionColumn'">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="hideEdit" class="actionButtons" (click)="onClick(row)"><i
                                    class="simple-icon-check"></i>
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                        [name]="item.name" [prop]="item.prop">
                    </ngx-datatable-column>
                    <ngx-datatable-footer *ngIf="true">
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                            let-curPage="curPage" let-offset="offset" let-limit="limit">
                            <div class="w-100 d-flex justify-content-center">
                                <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                                    (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                                    [customPreviousTemplate]="prevTemplate">
                                </pagination>
                            </div>
                        </ng-template>

                    </ngx-datatable-footer>
                </ngx-datatable>
                <ngx-datatable *ngIf="type === 2" [selected]="selectedRows" [columns]="_columns" [selectionType]="'checkbox'" (select)='onSelection($event)' [columnMode]="columnMode" [rows]="rows" [limit]="limit" [headerHeight]="headerHeight"
                    [scrollbarH]="scrollbarH" [footerHeight]="100" #table [sorts]="[{ prop: 'title', dir: 'asc' }]">
                    <ngx-datatable-footer *ngIf="true">
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                            let-curPage="curPage" let-offset="offset" let-limit="limit">
                            <div class="w-100 d-flex justify-content-center">
                                <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                                    (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                                    [customPreviousTemplate]="prevTemplate">
                                </pagination>
                            </div>
                        </ng-template>

                    </ngx-datatable-footer>
                </ngx-datatable> -->
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="type === 5">
        <button type="submit" class="btn btn-primary float-right" (click)="save()">Select</button>
    </div>
</ng-template>
<!-- <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-arrow-right'></i>
</ng-template>
<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-arrow-left'></i>
</ng-template> -->