// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { BizDatatableComponent } from './biz-datatable.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { BizDropdownModule } from '../biz-dropdown/biz-dropdown.module';
import { BizInputListerModule } from '../biz-input-lister/biz-input-lister.module';
import { BizInputModule } from '../biz-input/biz-input.module';
import { BizSelectModule } from '../biz-select/biz-select.module';

@NgModule({
    imports: [
       BizInputModule, NgxDatatableModule, FormsModule, ReactiveFormsModule, CommonModule, PaginationModule, BizDropdownModule, BizInputListerModule, BizSelectModule
    ],
    declarations: [
        BizDatatableComponent,
    ],
    exports: [
        BizDatatableComponent,
    ],
    providers: [[{ provide: PaginationConfig, useValue: { boundaryLinks: true, firstText: 'First', previousText: '&lsaquo;', nextText: '&rsaquo;', lastText: 'Last', maxSize: 1 } }]]
})
export class BizDatatableModule {

}
