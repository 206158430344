<!--component html goes here -->
<form [formGroup]="empForm">
    <accordion class="detail-tabs" [closeOthers]="false" [isAnimated]="true">
        <accordion-group heading="Employee Information" [isOpen]="true">
            <div class="row">
                <div class="col-lg-3">
                    <biz-input caption="ID" formControlName="employeeID" [disabled]="true" [design]="2"></biz-input>
                </div>
                <div class="col-lg-6">
                    <biz-select #empSelect caption="Employee" [data]="employeeData" [design]="5"
                        formControlName="fullName" apiController="Employee"
                        (change)="displayInfo(empForm.controls['fullName'].value, $event)" lookupType="Employee"
                        apiRoute="getEmployee" apiModule="apiURL" [required]="true" [valueMember]="'employeeID'"
                        [apiParameters]="employeeParams" [displayMember]="'fullName'">
                    </biz-select>
                </div>
                <div class="col-lg-3">
                    <biz-input caption="Code" formControlName="oldCardNo" [disabled]="true" [design]="2"></biz-input>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <biz-input caption="Department" formControlName="department" [disabled]="true" [design]="2">
                    </biz-input>
                </div>
                <div class="col-lg-3">
                    <biz-input caption="Designation" formControlName="designation" [disabled]="true" [design]="2">
                    </biz-input>
                </div>
                <div class="col-lg-3">
                    <biz-input caption="Joining Date" formControlName="jdate" type="date" [disabled]="true" [design]="2">
                    </biz-input>
                </div>
                <div class="col-lg-3">
                    <biz-input caption="Confirmation Date" formControlName="cdate" type="date" [disabled]="true" [design]="2">
                    </biz-input>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <biz-input caption="Group" formControlName="group" [disabled]="true" [design]="2">
                    </biz-input>
                </div>
                <div class="col-lg-3">
                    <biz-input caption="Employee Type" formControlName="type" [disabled]="true" [design]="2">
                    </biz-input>
                </div>
                <div class="col-lg-3">
                    <biz-input caption="Category" formControlName="category" [disabled]="true" [design]="2">
                    </biz-input>
                </div>
           
            </div>
        </accordion-group>
    </accordion>
</form>