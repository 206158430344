<!--component html goes here -->
<div class="row">
    <div class="col-12 md-4 data-table-rows actionGrid" style="margin-bottom: 15px;" *ngIf="_type !== 6">
        <div class="text-left" *ngIf="_type === 1" style="margin-top: 5px;margin-bottom: 5px;">
            <button *ngIf="hideAddRowbtn" type="button" [disabled]="disabledAddRowbtn" style="border-radius: 5px;"
                class="btn btn-primary btn-xs" (click)="addGridRow()">Add
                Row</button>
        </div>
        <ngx-datatable offset="100" [limit]="limit" [virtualization]="false" #table *ngIf="_type === 1"
            [columnMode]="columnMode" [rows]="_rows" [headerHeight]="headerHeight" [scrollbarH]="scrollbarH"
            [scrollbarV]="true" [footerHeight]="0" [sorts]="[{ prop: 'title', dir: 'asc' }]" style="height: 250px;">
            <ngx-datatable-column *ngIf="isAction" frozenLeft="frozenLeft" name="Action" [flexGrow]="5"
                [width]="actionColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <button [id]="loader+rowIndex"
                        [class]="buttonState + ' ' + 'btn' + ' btn-multiple-state btnState-Action'" [popover]="message"
                        triggers="">
                        <div class="spinner d-inline-block" style="z-index: 10;">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <span class="icon success" data-toggle="tooltip" data-placement="top"
                            title="Everything went right!">
                            <i class="simple-icon-check"></i>
                        </span>
                        <span class="icon fail" data-toggle="tooltip" data-placement="top"
                            title="Something went wrong!">
                            <i class="simple-icon-exclamation"></i>
                        </span>
                        <span class="label">
                            <span *ngIf="hideSave" data-toggle="tooltip" title="Save" data-placement="top"
                                class="actionButtons" (click)="Edit(row, rowIndex)"><i class="iconsminds-save"></i>
                            </span>
                            <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top"
                                class="actionButtons" (click)="Edit(row, rowIndex)"><i class="simple-icon-note"></i>
                            </span>
                            <span *ngIf="hideView" data-toggle="tooltip" title="View" data-placement="top"
                                class="actionButtons" (click)="View(row)"><i class="simple-icon-eye"></i></span>
                            <span *ngIf="hideDelete" data-toggle="tooltip" title="Delete" data-placement="top"
                                class="actionButtons" (click)="Delete(row, rowIndex)"><i
                                    class="simple-icon-close"></i></span>
                        </span>
                    </button>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns;let indexOfelement=index;"
                [width]="item.width ? item.width : 100" [name]="item.name" [prop]="item.prop">
                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <biz-input *ngIf="item.type === 1" type="text"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 3" type="date"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 4" type="number"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <div *ngIf="item.type === 2">
                        <biz-dropdown [id]="item.prop+rowIndex" caption="" [value]="value"
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="5" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 6">
                        <biz-dropdown #item.prop+rowIndex
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            [id]="item.prop+rowIndex" caption="" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="3" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 8">
                        <biz-inputLister #item.prop+rowIndex [rowIndex]="rowIndex" [params]="item.parameters"
                            [lookupType]="item.lookupType"
                            (BeforeOpen)="BeforeOpen($event, rowIndex, item.prop, item, row)"
                            (initialized)="inputListerInitialized($event, rowIndex, item.prop, item, row)"
                            [isdependent]="item.isListerdependent" [defaultValues]="item.defaultValues" [row]="row"
                            [id]="item.prop+rowIndex" caption="" [apiController]="item.apiController"
                            [apiRoute]="item.apiRoute" [apiModuleName]="item.apiModuleName" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [data]="item.dropdownData" [config]="item.config" [isDisabled]="item.isDisabled"
                            [inputType]="item.inputType" [design]="item.design" [valueMember]="item.valueMember"
                            [displayMember]="item.displayMember" [isButton]="item.isButton">
                        </biz-inputLister>
                    </div>
                    <div *ngIf="item.type === 10">
                        <biz-select #item.prop+rowIndex (initialized)="dropdownInitialized($event)"
                            [id]="item.prop+rowIndex" caption="" [value]="value" [lookupType]="item.lookupType"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="4" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-select>
                    </div>
                    <button *ngIf="item.type === 5" [id]="item.prop" type="button"
                        class="btn btn-outline-primary btn-xs" style="margin-top: -3px;"
                        (click)="buttonClick(row)">{{item.name}}</button>
                    <input *ngIf="item.type === 7" type="checkbox" [checked]="value"
                        (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                        [disabled]="!item.editable"
                        style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />
                </ng-template>
            </ngx-datatable-column>
            <!-- frozenRight="frozenRight" -->
            <ngx-datatable-column *ngIf="isCustomButton" frozenLeft="frozenLeft" [name]="CustomColumnName"
                [flexGrow]="5" [width]="customColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngFor="let b of _customButtonList" class="actionButtons" data-toggle="tooltip"
                        [title]="b.name" data-placement="top" (click)="customButtonClick(b, row)"><i
                            [ngClass]="b.icon ? b.icon : 'simple-icon-eye'"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        <ngx-datatable #table *ngIf="_type === 3" [columnMode]="columnMode" [rows]="_rows" [limit]="limit"
            [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [scrollbarV]="scrollbarV"
            [footerHeight]="footerHeight" [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-column name="Action" *ngIf="isActionColumn" frozenLeft="frozenLeft" [flexGrow]="5" [width]="actionColumnWidth"
                [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top" class="actionButtons"
                        (click)="Edit(row, rowIndex)"><i class="simple-icon-note"></i>
                    </span>
                    <span *ngIf="hideView" data-toggle="tooltip" title="View" data-placement="top" class="actionButtons"
                        (click)="View(row)"><i class="simple-icon-eye"></i></span>
                    <span *ngIf="hideDelete" data-toggle="tooltip" title="Delete" data-placement="top"
                        class="actionButtons" (click)="Delete(row)"><i class="simple-icon-close"></i></span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop">
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="isCustomButton" frozenLeft="frozenLeft" [name]="CustomColumnName"
                [flexGrow]="5" [width]="customColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngFor="let b of _customButtonList" class="actionButtons" data-toggle="tooltip"
                        [title]="b.name" data-placement="top" (click)="customButtonClick(b, row)"><i
                            [ngClass]="b.icon ? b.icon : 'simple-icon-eye'"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
        <ngx-datatable #table *ngIf="_type === 2" [columnMode]="columnMode" [rows]="_rows" [limit]="limit"
            [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [footerHeight]="footerHeight"
            [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop">
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>

        <ngx-datatable #table *ngIf="_type === 4" [columnMode]="columnMode" [rows]="_rows" [limit]="limit"
            [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [footerHeight]="100"
            [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-column name="Action" frozenLeft="frozenLeft" [flexGrow]="5" [width]="70"
                [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top" class="actionButtons"
                        (click)="Click(row)"><i class="simple-icon-check"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop">
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
        <ngx-datatable #table *ngIf="_type === 7" [columnMode]="columnMode" [rows]="_rows" [limit]="limit"
            [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [scrollbarV]="true" [footerHeight]="0"
            [sorts]="[{ prop: 'title', dir: 'asc' }]" style="height: 300px;">
            <ngx-datatable-column name="Action" frozenLeft="frozenLeft" [flexGrow]="5" [width]="70"
                [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span *ngIf="hideEdit" data-toggle="tooltip" title="Edit" data-placement="top" class="actionButtons"
                        (click)="Click(row)"><i class="simple-icon-check"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                [name]="item.name" [prop]="item.prop" [cellClass]="cellClassMethod">
            </ngx-datatable-column>
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
        <ngx-datatable #table *ngIf="_type === 5" [selected]="selectedRows" [columns]="_columns"
            [selectionType]="'checkbox'" (select)='Selection($event)' [columnMode]="columnMode" [rows]="_rows"
            [limit]="limit" [headerHeight]="headerHeight" [scrollbarH]="scrollbarH" [footerHeight]="100"
            [sorts]="[{ prop: 'title', dir: 'asc' }]">
            <ngx-datatable-footer *ngIf="pagination">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>

            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div class="col-12 mb-4 data-grid">
        <ngx-datatable class="material" [limit]="limit" [virtualization]="false" #table *ngIf="_type === 6"
            [columnMode]="columnMode" [rows]="_rows" [headerHeight]="headerHeight" [scrollbarH]="scrollbarH"
            [treeFromRelation]="treeFromRelation" [treeToRelation]="treeToRelation" [scrollbarV]="true"
            (treeAction)="TreeAction($event)" [footerHeight]="0" [rowClass]="getRowClass" style="height: 250px;">
            <ngx-datatable-column name="" [flexGrow]="3" [isTreeColumn]="true" [width]="30">
                <ng-template ngx-datatable-tree-toggle let-tree="cellContext">
                    <i
                        [class]="tree.treeStatus === 'loading' ? 'simple-icon-options' : tree.treeStatus === 'collapsed' ? 'simple-icon-arrow-down' : tree.treeStatus === 'expanded' ? 'simple-icon-arrow-up' : tree.treeStatus === 'disabled' ? 'simple-icon-options-vertical' : ' '">

                    </i>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngFor="let item of _columns;let indexOfelement=index;"
                [width]="item.width ? item.width : 100" [name]="item.name" [prop]="item.prop">
                <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <biz-input *ngIf="item.type === 1" type="text"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 3" type="date"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <biz-input *ngIf="item.type === 4" type="number"
                        (initialized)="inputInitialized($event, rowIndex, item.prop, item, row)"
                        (keyup.enter)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [value]="value"
                        (change)="valueChange($event.target.value, rowIndex, item.prop, item, row)" [design]="3"
                        [disabled]="!item.editable">
                    </biz-input>
                    <div *ngIf="item.type === 2">
                        <biz-dropdown [id]="item.prop+rowIndex" caption="" [value]="value"
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="5" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 6">
                        <biz-dropdown #item.prop+rowIndex
                            (initialized)="dropdownInitialized($event,rowIndex, item.prop, item, row)"
                            [id]="item.prop+rowIndex" caption="" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="3" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-dropdown>
                    </div>
                    <div *ngIf="item.type === 8">
                        <biz-inputLister #item.prop+rowIndex [rowIndex]="rowIndex" [params]="item.parameters"
                            [lookupType]="item.lookupType"
                            (BeforeOpen)="BeforeOpen($event, rowIndex, item.prop, item, row)"
                            (initialized)="inputListerInitialized($event, rowIndex, item.prop, item, row)"
                            [isdependent]="item.isListerdependent" [defaultValues]="item.defaultValues" [row]="row"
                            [id]="item.prop+rowIndex" caption="" [apiController]="item.apiController"
                            [apiRoute]="item.apiRoute" [apiModuleName]="item.apiModuleName" [value]="value"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [data]="item.dropdownData" [config]="item.config" [isDisabled]="item.isDisabled"
                            [inputType]="item.inputType" [design]="item.design" [valueMember]="item.valueMember"
                            [displayMember]="item.displayMember" [isButton]="item.isButton">
                        </biz-inputLister>
                    </div>
                    <div *ngIf="item.type === 10">
                        <biz-select #item.prop+rowIndex (initialized)="dropdownInitialized($event)"
                            [id]="item.prop+rowIndex" caption="" [value]="value" [lookupType]="item.lookupType"
                            (change)="valueChange($event, rowIndex, item.prop, item, row)" [width]="item.width"
                            [design]="4" [data]="item.dropdownData" [config]="item.config" [disabled]="!item.editable"
                            [valueMember]="item.valueMember" [displayMember]="item.displayMember">
                        </biz-select>
                    </div>
                    <button *ngIf="item.type === 5" [id]="item.prop" type="button"
                        class="btn btn-outline-primary btn-xs" style="margin-top: -3px;"
                        (click)="buttonClick(row)">{{item.name}}</button>
                    <input *ngIf="item.type === 7" type="checkbox" [checked]="value"
                        (change)="valueChange($event.target.checked, rowIndex, item.prop,item, row)"
                        [disabled]="!item.editable"
                        style="margin-top: -3px;background: transparent;border: none;width: -webkit-fill-available;" />
                </ng-template>
            </ngx-datatable-column>
            <!-- frozenRight="frozenRight" -->
            <ngx-datatable-column *ngIf="isCustomButton" frozenLeft="frozenLeft" [name]="CustomColumnName"
                [flexGrow]="5" [width]="customColumnWidth" [cellClass]="'actionColumn'">
                <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span *ngFor="let b of _customButtonList" class="actionButtons" data-toggle="tooltip"
                        [title]="b.name" data-placement="top" (click)="customButtonClick(b, row)"><i
                            [ngClass]="b.icon ? b.icon : 'simple-icon-eye'"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-arrow-right'></i>
</ng-template>
<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
    <i class='simple-icon-arrow-left'></i>
</ng-template>
<style>
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
</style>