import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseService } from '../../Services/base.service';
import { Broadcast } from '../../Services/broadcast.service';
import { ServiceLocator } from '../../Services/locator.service';
import { BizDatatableComponent } from '../biz-datatable/biz-datatable.component';
import { Enum } from '../config';
import * as moment from 'moment';

@Component({
    selector: 'issuance-lookup',
    templateUrl: 'issuance-lookup.component.html',
})
export class IssuanceLookupComponent implements OnInit, AfterViewInit {
    @Input() defaultValues: any[] = [];
    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() pagination: boolean = true;
    @Input() caption: string = "Issuance Lookup";
    @Input() rowIndex: any;
    itemOptionsOrders: any;
    itemOrder: any;
    buttonDisabled = false;
    buttonState = '';
    // @Input() type: number = 1;

    @Input() public selectedRows: any[] = [];

    @ViewChild('lookupModal') lookupModal: TemplateRef<any>;
    @ViewChild('table') table: BizDatatableComponent;

    public _columns: any[] = [];
    public _type: number = 5;
    modalRef: any;
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            // let selectCol = {
            //     prop: 'selected',
            //     name: '',
            //     sortable: false,
            //     canAutoResize: false,
            //     draggable: false,
            //     resizable: false,
            //     headerCheckboxable: true,
            //     checkboxable: true,
            //     width: 50
            // };
            // this._columns.splice(0, 0, selectCol);
        }
    }

    private baseService: any;

    constructor(private modalService: BsModalService, private broadcast: Broadcast) {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    SyncBack: EventEmitter<any> = new EventEmitter<any>();

    store: any[] = [];
    head: any[] = [];

    // lookupForm = new FormGroup({
    //     store: new FormControl(''),
    //     acchead: new FormControl('')
    // });

    async ngOnInit() {
        this.columns = [
            // {
            //     prop: 'selected',
            //     name: '',
            //     sortable: false,
            //     canAutoResize: false,
            //     draggable: false,
            //     resizable: false,
            //     headerCheckboxable: true,
            //     checkboxable: true,
            //     width: 50,
            //     frozenLeft: 'frozenLeft'
            // },
            {
                prop: 'fkAccReqID',
                name: 'Req #',
                width: 80
            },
            {
                prop: 'date',
                name: 'Req Date',
                width: 130
            },
            {
                prop: 'store',
                name: 'Store',
                width: 150
            },
            {
                prop: 'fkAccItemMasterID',
                name: 'Item ID',
                width: 100
            },
            {
                prop: 'name',
                name: 'Accessory Name',
                width: 150
            },
            {
                prop: 'qtyRequired',
                name: 'Indent QTY',
                width: 100
            },
            {
                prop: 'fkppdid',
                name: 'PPD No',
                width: 100
            },
            {
                prop: 'leather',
                name: 'PPD Color',
                width: 130
            },
            {
                prop: 'department',
                name: 'Department',
                width: 130
            },
            {
                prop: 'storeRemarks',
                name: 'Remarks',
                width: 130
            },
        ];
        this.itemOrder = { label: 'All', value: 'tType' };
        this.itemOptionsOrders = [
            { label: 'All', value: 'tType' },
            {
                value: 'fkAccReqID',
                label: 'Req #',
            },
            {
                value: 'date',
                label: 'Req Date',
            },
            {
                value: 'Store',
                label: 'store',
            },
            {
                value: 'fkAccItemMasterID',
                label: 'Item ID',
            },
            {
                value: 'name',
                label: 'Accessory Name',
            },
            {
                value: 'qtyRequired',
                label: 'Indent QTY',
            },
            {
                value: 'fkppdid',
                label: 'PPD No',
            },
            {
                value: 'leather',
                label: 'PPD Color',
            },
            {
                value: 'department',
                label: 'Department',
            },
            {
                value: 'storeRemarks',
                label: 'Remarks',
            },
        ];
    }

    async getIndentApprovalList() {
        let val;

        this.buttonState = 'show-spinner';
        this.buttonDisabled = true;

        val = 'tType=' + 0 + '&SegmentID=' + localStorage.getItem('LocationID') + '&UserID=' + localStorage.getItem('UserID');

        await this.baseService.get('AccessoryIssued', 'getIndentApprovalList', val, 'Accessories').then(o => {
            this.buttonState = '';
            this.buttonDisabled = false;
            if (o && o.length && o.length > 0) {
                o.map(u => {
                    u.date = moment(u.requestDAte).format('DD-MMM-YYYY')
                })
                this.rows = o;
            } else {
                // return [];
            }
        })
    }

    async getSearchBy(filed, value) {
        let val;

        this.buttonState = 'show-spinner';
        this.buttonDisabled = true;

        val = 'SegmentID=' + localStorage.getItem('LocationID') + '&filed=' + filed + '&value=' + value;

        await this.baseService.get('AccessoryIssued', 'getIndentSearchBy', val, 'Accessories').then(o => {
            this.buttonState = '';
            this.buttonDisabled = false;
            if (o && o.length && o.length > 0) {
                o.map(u => {
                    u.date = moment(u.requestDAte).format('DD-MMM-YYYY')
                })
                this.rows = o;
            } else {
                // return [];
            }
        })
    }

    async ngAfterViewInit() { }

    async open() {


        await this.getIndentApprovalList();


        setTimeout(() => {
            this.caption = this.caption && this.caption !== "" ? this.caption : 'Lookup'
            this.modalRef = this.modalService.show(this.lookupModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-xl',
            });

        }, 0);

    }

    @Input()
    public LookupId: String = "";

    Click(row) {

        let obj = {
            grid: this.table,
            row: row,
            data: this.rows
        }

        if (obj) {

            if (this.modalRef)
                this.modalRef.hide()
            // this.broadcast.publish<LookupResult>("lookup", <LookupResult>{
            //     lookupId: this.LookupId,
            //     data: row,
            // });

            this.onClick.emit(obj);
        }
    }

    onSelection(selection) {

        if (selection) {
            this.selectedRows = selection
        }
    }

    save() {
        
        if (this.selectedRows.length > 0) {
            
            this.modalRef.hide();
            this.SyncBack.emit(this.selectedRows)

        }
    }

    itemsPerPageChange(e) {

    }
    tempData: any[] = []
    async searchByButton(obj) {

        if (obj && obj.value) {
            if (obj.item.label === "All") {
                await this.getIndentApprovalList();
            } else {
                await this.getSearchBy(obj.item.value, obj.value)
            }
        }

    }
    searchKeyUp(obj): void {

        const val = obj.event.target.value;

        if (val === "" && this.tempData.length > 0) {

            this.rows = [...this.tempData]
            this.tempData = [];
        }

        if (this.tempData.length === 0) this.tempData = this.rows;

        this.rows = this.rows.filter(i => i[obj.item.value].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== - 1);

    };

}

export interface LookupResult {
    lookupId: string;
    data: any;
}
