<div class="customTabs">
    <app-list-page-header displayMode="list" [showOrderBy]="false" [showBtnDropDown]="false"
        [showDisplayOptions]="false">
    </app-list-page-header>
    <form [formGroup]="myForm">
        <accordion class="detail-tabs" [closeOthers]="false" [isAnimated]="true">
            <accordion-group heading="PPD Filter" [isOpen]="true">
                <div class="row">
                    <div class="col-lg-3">
                        <biz-select caption="PPD" [data]="" [design]="1" formControlName="ppdid" apiModule="apiURL"
                            apiController="SalesPPDAccessoriesUsed" apiRoute="getJOBPPD" [valueMember]="'ppdid'"
                            [apiParameters]="ppdParams" (change)="onEnter(myForm.controls['ppdid'].value)"
                            [displayMember]="'detailName'">
                        </biz-select>
                    </div>
                    <div class="col-lg-3">
                        <biz-input caption="PPD Input" (keydown.enter)="onEnter(myForm.controls['ppdid'].value)"
                            formControlName="ppdid" type="number" [design]="2">
                        </biz-input>
                    </div>
                    <div class="col-lg-6">
                        <div class="text-left">
                            <button type="button" class="btn btn-primary default mb-1 btn-xs pt-2"
                                (click)="getDate()"> Fetch </button>
                        </div>
                    </div>
                </div>
            </accordion-group>
        </accordion>
    </form>
    <app-glide class="remove-glid-arrow border-bottom-glide" *ngIf="headerMenu.length > 0" [settings]="{
            gap: 10,
            type: 'carousel',
            perView: 10,
            breakpoints: { '800': { perView: 2 }, '480': { perView: 1 }}
        }">
        <div *ngFor="let item of headerMenu" class="glide__slide">
            <div [class]="selectHeader.id === item.id ? 'card isActive' : 'card'" style="cursor: pointer;"
                (click)="setSelectheader(item)">
                <div class="card-body text-center" style="padding: 5px;">
                    <i [class]="item.css_Class" style="font-size: 40px;"></i>
                    <p class="card-text font-weight-semibold mb-0"> {{item.menuName}} </p>
                </div>
            </div>
        </div>
    </app-glide>
    <div *ngIf="selectHeader.id === 1">
        <div class="row">
            <div class="col-lg-12">
                <div class="card profile-box flex-fill mb-2">
                    <div class="card-body">
                        <h3 class="card-title">PPD Informations <span *ngIf="!data.reviewBy" type="button"
                                class="edit-icon-review" (click)="ReviewSave()"><i class="simple-icon-note"></i>
                                Review</span> <span class="text color-theme-1 float-right"
                                style="font-size: 12px;font-weight: 700;" *ngIf="data.reviewBy"> Review Date:
                                {{data.reviewDate | date:'fullDate'}} / ReviewBy: {{data.reviewByName}} </span></h3>
                        <div class="row">
                            <div class="col-lg-6">
                                <ul class="personal-info">
                                    <li>
                                        <div class="title">Date</div>
                                        <div class="text">{{data.orderDate | date:'fullDate'}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Model</div>
                                        <div class="text">{{data.modelName}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Customer</div>
                                        <div class="text">{{data.customerName}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Season</div>
                                        <div class="text">{{data.seasonName}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Shipment By</div>
                                        <div class="text">{{data.shipBy}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Agent</div>
                                        <div class="text">{{data.agentName}}</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <ul class="personal-info">
                                    <li>
                                        <div class="title">Type</div>
                                        <div class="text">{{data.type}}</div>
                                    </li>
                                    <li>
                                        <div class="title">PPD Type</div>
                                        <div class="text">{{data.ppdType}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Customer Refrence</div>
                                        <div class="text">{{data.custRef}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Destination</div>
                                        <div class="text">{{data.destinationName}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Payment Term</div>
                                        <div class="text">{{data.payTerm}}</div>
                                    </li>
                                    <li>
                                        <div class="title">Agent2</div>
                                        <div class="text">{{data.fkAgentID1}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <biz-datatable #table [hideAddRowbtn]="false" (onCustomButtonClick)="onCustomButtonClick($event)"
                _gridHeight="150px" [pagination]="false" [isCustomButton]="true" [rows]="detailList"
                [customButtonList]="customButtonList" [columns]="columnDefIP">
            </biz-datatable>
        </div>
        <div class="col-lg-12 data-table-rows actionGrid rowColor grouping" *ngIf="accessoriesList.length > 0">
            <ngx-datatable #accTable id="accTable" [columnMode]="'force'" [virtualization]="false"
                [rows]="accessoriesList" [limit]="5" style="height: 350px;" [headerHeight]="30" [scrollbarH]="true"
                [scrollbarV]="true" [footerHeight]="0" [groupRowsBy]="'headAccessory'" [groupExpansionDefault]="false"
                [sorts]="[{ prop: 'title', dir: 'asc' }]">
                <ngx-datatable-group-header #myGroupHeader>
                    <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                        <div style="padding-left:5px;background: #e9e6e6;cursor: pointer;">
                            <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                                title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
                                <b style="font-size: 15px;">{{ group.value[0].headAccessory }}</b> ( <span> Total Items:
                                    {{group.value.length}} / Total Qty: {{getSum(group)}} </span> )
                            </a>
                        </div>
                    </ng-template>
                </ngx-datatable-group-header>
                <ngx-datatable-column *ngFor="let item of columnDefAccessories" [width]="item.width ? item.width : 150"
                    [name]="item.name" [prop]="item.prop">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <span style="cursor: pointer;" (click)="cellClickGroupBy(row, item.prop, item.name)"> {{value}}
                        </span>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>

        </div>
    </div>
    <div *ngIf="selectHeader.id === 3">
        <!-- <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 border-top-bottom">
                <app-glide class="remove-glid-arrow" *ngIf="requestMenu.length > 0" [settings]="{
            type: 'carousel',
            perView: 5,
            breakpoints: { '800': { perView: 2 }, '480': { perView: 1 }}
        }">
                    <div *ngFor="let item of requestMenu" class="glide__slide">
                        <div [class]="selectRequest.id === item.id ? 'card isActive' : 'card'" style="cursor: pointer;"
                            (click)="setSelectRequest(item)">
                            <div class="card-body text-center" style="padding: 5px;">
                                <i [class]="item.css_Class" style="font-size: 20px;"></i>
                                <p class="card-text font-weight-semibold mb-0"> {{item.menuName}} </p>
                            </div>
                        </div>
                    </div>
                </app-glide>
            </div>
            <div class="col-lg-3"></div>
        </div> -->
        <div class="row">
            <div class="col-lg-12">
                <tabset class="card-tabs" (click)="onTabClick($event)">
                    <tab heading="Leather Inquiry" class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-left">
                                    <button type="button"
                                        data-toggle="tooltip" title="Add New" data-placement="top" (click)="isNew('Leather Inquiry')"
                                        class="btn btn-primary default mb-1 btn-xs pt-2"> <i
                                            class="simple-icon-plus"></i> Add New
                                    </button>
                                </div>
                                <biz-datatable *ngIf="gridColumn.length > 0" #tableContrast [type]="4" [limit]="5"
                                    [hideAddRowbtn]="false" [rows]="gridData" [columns]="gridColumn"
                                    (onClick)="Action($event)">
                                </biz-datatable>
                            </div>
                        </div>
                    </tab>
                    <tab heading="Leather Issued" class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-left">
                                    <button type="button"
                                        data-toggle="tooltip" title="Add New" data-placement="top" (click)="isNew('Leather Issued')"
                                        class="btn btn-primary default mb-1 btn-xs pt-2"> <i
                                            class="simple-icon-plus"></i> Add New
                                    </button>
                                </div>
                                <biz-datatable *ngIf="gridColumn.length > 0" #tableContrast [type]="4" [limit]="5"
                                    [hideAddRowbtn]="false" [rows]="gridData" [columns]="gridColumn"
                                    (onClick)="Action($event)">
                                </biz-datatable>
                            </div>
                        </div>
                    </tab>
                    <tab heading="Accessories Purchase" class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-left">
                                    <button type="button"
                                        data-toggle="tooltip" title="Add New" data-placement="top" (click)="isNew('Accessories Purchase')"
                                        class="btn btn-primary default mb-1 btn-xs pt-2"> <i
                                            class="simple-icon-plus"></i> Add New
                                    </button>
                                </div>
                                <biz-datatable *ngIf="gridColumn.length > 0" #tableContrast [type]="4" [limit]="5"
                                    [hideAddRowbtn]="false" [rows]="gridData" [columns]="gridColumn"
                                    (onClick)="Action($event)">
                                </biz-datatable>
                            </div>
                        </div>
                    </tab>
                    <tab heading="Accessories Issued" class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-left">
                                    <button type="button"
                                        data-toggle="tooltip" title="Add New" data-placement="top" (click)="isNew('Accessories Issued')"
                                        class="btn btn-primary default mb-1 btn-xs pt-2"> <i
                                            class="simple-icon-plus"></i> Add New
                                    </button>
                                </div>
                                <biz-datatable *ngIf="gridColumn.length > 0" #tableContrast [type]="4" [limit]="5"
                                    [hideAddRowbtn]="false" [rows]="gridData" [columns]="gridColumn"
                                    (onClick)="Action($event)">
                                </biz-datatable>
                            </div>
                        </div>
                    </tab>
                    <tab heading="Shipment Request" class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-left">
                                    <button type="button"
                                        data-toggle="tooltip" title="Add New" data-placement="top" (click)="isNew('Shipment Request')"
                                        class="btn btn-primary default mb-1 btn-xs pt-2"> <i
                                            class="simple-icon-plus"></i> Add New
                                    </button>
                                </div>
                                <biz-datatable *ngIf="gridColumn.length > 0" #tableContrast [type]="4" [limit]="5"
                                    [hideAddRowbtn]="false" [rows]="gridData" [columns]="gridColumn"
                                    (onClick)="Action($event)">
                                </biz-datatable>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>

    </div>
    <div *ngIf="selectHeader.id === 6">
        <app-chat [ppdid]="myForm.controls['ppdid'].value" [isGroup]="false"></app-chat>
    </div>
    <div *ngIf="selectHeader.id === 7">
        <timeline [ppdid]="myForm.controls['ppdid'].value"></timeline>
    </div>
</div>
<simple-notifications></simple-notifications>
<style>
    .edit-icon-review {
        background-color: #db3838;
        border: 1px solid #802121;
        border-radius: 24px;
        color: #fff;
        float: right;
        font-size: 12px;
        line-height: 24px;
        min-height: 26px;
        text-align: center;
        cursor: pointer;
        padding: 0px 15px;
    }

    .profile-view {
        position: relative;
    }

    .profile-view .profile-img-wrap {
        height: 120px;
        width: 120px;
    }

    .profile-img-wrap {
        height: 120px;
        position: absolute;
        width: 120px;
        background: #fff;
        overflow: hidden;
    }

    .profile-view .profile-img {
        width: 120px;
        height: 120px;
    }

    .profile-view .profile-img {
        width: 120px;
        height: 120px;
    }

    .profile-img-wrap img {
        border-radius: 50%;
        height: 120px;
        width: 120px;
    }

    .profile-view .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .profile-view .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .user-name {
        color: #333;
    }

    .text-muted {
        color: #8e8e8e !important;
    }

    .text-muted {
        --bs-text-opacity: 1;
        color: #6c757d !important;
    }

    .staff-id {
        font-size: 14px;
        font-weight: 500;
        margin-top: 5px;
    }

    .staff-msg {
        margin-top: 30px;
    }

    .staff-msg {
        margin-top: 30px;
    }

    .personal-info li {
        margin-bottom: 10px;
    }

    .personal-info li .title {
        color: #4f4f4f;
        float: left;
        font-weight: 500;
        margin-right: 30px;
        width: 35%;
    }

    .profile-info-left-b {
        border-left: 2px dashed #ccc;
    }

    .personal-info li .text {
        color: #8e8e8e;
        display: block;
        overflow: hidden;
    }

    .profile-view .pro-edit {
        position: absolute;
        right: 0;
        top: 0;
    }

    .edit-icon {
        background-color: #eee;
        border: 1px solid #e3e3e3;
        border-radius: 24px;
        color: #bbb;
        float: right;
        font-size: 12px;
        line-height: 24px;
        min-height: 26px;
        text-align: center;
        width: 26px;
    }

    .profile-box {
        min-height: 250px;
    }

    .flex-fill {
        flex: 1 1 auto !important;
    }

    .card-title {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .experience-box {
        position: relative;
    }

    .experience-list {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
    }

    .experience-list::before {
        background: #ddd;
        bottom: 0;
        content: "";
        left: 8px;
        position: absolute;
        top: 8px;
        width: 2px;
    }

    .experience-list>li {
        position: relative;
    }

    .experience-list>li .experience-user {
        background: #fff;
        height: 10px;
        left: 4px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 4px;
        width: 10px;
    }

    .before-circle {
        background-color: #ddd;
        border-radius: 50%;
        height: 10px;
        width: 10px;
    }

    .experience-list>li .experience-content {
        background-color: #fff;
        margin: 0 0 20px 40px;
        padding: 0;
        position: relative;
    }

    .experience-list>li .experience-content .timeline-content {
        color: #9e9e9e;
    }

    .experience-list>li .experience-content .timeline-content a.name {
        color: #616161;
        font-weight: 700;
    }

    .experience-list>li .time {
        color: #bdbdbd;
        display: block;
        font-size: 12px;
        line-height: 1.35;
    }

    .avatar.avatar-xs {
        width: 24px;
        height: 24px;
    }

    .avatar {
        background-color: #aaa;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        font-weight: 500;
        height: 38px;
        line-height: 38px;
        margin: 0 10px 0 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        width: 38px;
        position: relative;
        white-space: nowrap;
    }

    .avatar>img {
        border-radius: 50%;
        display: block;
        overflow: hidden;
        width: 100%;
    }

    @media only screen and (max-width: 575.98px) {
        .profile-img-wrap {
            position: relative;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 575.98px) {
        .profile-view .profile-basic {
            margin-left: 0;
            padding-right: 0;
        }
    }

    @media only screen and (max-width: 575.98px) {
        .profile-info-left {
            text-align: center;
        }
    }

    @media only screen and (max-width: 767.98px) {
        .profile-info-left {
            border-right: none;
            border-bottom: 2px dashed #ccc;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    @media only screen and (max-width: 991.98px) {
        .profile-info-left {
            border-right: none;
            border-bottom: 2px dashed #ccc;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }
</style>

<ng-template #contrastModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Contrast</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="contrastmodalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">
                <biz-datatable #tableContrast [hideAddRowbtn]="false"
                    (initialized)="ContrastComponentInitialized($event)" id="tableContrast" [rows]="constrastdtl"
                    [columns]="columnDefContrast">
                </biz-datatable>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary float-right" (click)="contrastmodalRef.hide()">Close</button>
    </div>
</ng-template>

<ng-template #sizeModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Size Breaker</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="sizemodalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="ContrastCtrl">
            <div class="row">
                <div class="col-lg-12">
                    <biz-datatable #tableSize [hideAddRowbtn]="false" (initialized)="SizeComponentInitialized($event)"
                        [rows]="sizebreakerdtl" [columns]="columnDefSize" (onCellValueChange)="changeExtraPcs($event)">
                    </biz-datatable>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary float-right" (click)="sizemodalRef.hide()">Close</button>
    </div>
</ng-template>